<template>
  <Modal :display="display" title="Add debit card" @close="$emit('close')">
    <p class="text-gret text-sm">To make your next payments easier, you need to add your debit card</p>
    <p class="mt-3 text-grey text-sm">You’ll be charged N50 for this action – the charge will be added back to your wallet automatically.</p>
    <AddCard :startAddCard="startAddCard" @close="handleCardClose">
      <Button text="Add debit card" width="w-full" class="mt-8" @click="startAddCard = true" />
    </AddCard >
    <p class="mt-4 font-semibold text-brandPurple cursor-pointer text-center" @click="$emit('close')">Cancel</p>
  </Modal>
</template>
<script>
  import { Modal } from "@/UI/Modals";
  import { Button } from "@/UI/Button";
  export default {
    components: {
      Modal,
      Button,
      AddCard: () => import("../Verification/AddCard"),
    },
    props: {
      display: {
        type: Boolean,
        default: false,
        required: true,
      },
    },
    data: () => ({
      startAddCard: false,
    }),
    methods:{
      handleCardClose(){
        this.$emit('close')
      }
    }
  };
</script>
