var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: { display: _vm.display, title: "Add debit card" },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("p", { staticClass: "text-gret text-sm" }, [
        _vm._v(
          "To make your next payments easier, you need to add your debit card"
        ),
      ]),
      _c("p", { staticClass: "mt-3 text-grey text-sm" }, [
        _vm._v(
          "You’ll be charged N50 for this action – the charge will be added back to your wallet automatically."
        ),
      ]),
      _c(
        "AddCard",
        {
          attrs: { startAddCard: _vm.startAddCard },
          on: { close: _vm.handleCardClose },
        },
        [
          _c("Button", {
            staticClass: "mt-8",
            attrs: { text: "Add debit card", width: "w-full" },
            on: {
              click: function ($event) {
                _vm.startAddCard = true
              },
            },
          }),
        ],
        1
      ),
      _c(
        "p",
        {
          staticClass:
            "mt-4 font-semibold text-brandPurple cursor-pointer text-center",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("Cancel")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }